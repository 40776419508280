.action-button {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  line-height: 20px;
  text-align: center;
  padding: 11px;
  background: var(--button-action-background);
  border-radius: var(4px);
  color: var(--button-action-text);
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background: var(--button-action-background-hover);
  }
}
.action-button.full-width {
  clear: both;
  float: none;
  width: 100%;
}
