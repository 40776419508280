/*Themes*/
.button.trigger {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  padding: 10px 10px;
  min-height: 20px;
  transition: all 0.5s;
  color: #fff;
  align-items: center;
  transition: all 0.25s ease-in-out;
  span {
    height: 20px;
    line-height: 20px;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    color: inherit;
    text-align: center;
    font-weight: 600;
  }
  svg,
  i {
    transition: all 0.25s ease-in-out;
    font-size: 13pt;
  }
  i.pad {
    margin-right: 8px;
  }
}
.button.trigger.skeleton {
  border: 1px solid transparent;
}
.button.trigger.default {
  background: var(--button-action-background);
  border: 1px solid var(--button-action-border);
  color: var(button-action-text);
  &:hover,
  &:focus {
    background: var(--button-action-background-hover);
  }
}
.button.trigger.light {
  background: var(--button-light-background);
  border: 1px solid var(--button-light-border);
  color: var(--button-light-text);
  &:hover,
  &:focus {
    background: var(--button-light-background-hover);
  }
}
.button.trigger.red {
  background: var(--button-warning-background);
  border: 1px solid var(--button-warning-border);
  color: var(--button-warning-text);
  &:hover,
  &:focus {
    background: var(--button-warning-background-hover);
    color: var(--button-warning-text-hover);
  }
}
.button.trigger.disabled {
  background: #fff !important;
  border: 1px solid var(--grey-border) !important;
  color: #999 !important;
  cursor: not-allowed !important;
}
