:root {
  --background-main: rgb(241, 241, 242);
  --text-default: #333;
  --border-color: #ddd;
  --charcoal: rgb(64, 64, 65);
  --green: rgb(104, 171, 11);
  --forest: rgb(26, 50, 52);
  --grey: rgb(183, 187, 183);
  --aqua: rgb(0, 107, 118);

  //Buttons
  --button-action-background: rgb(64, 64, 65);
  --button-action-background-hover: rgb(80, 80, 80);
  --button-action-border: rgb(64, 64, 65);
  --button-action-text: #fff;
  --button-warning-background: #fff;
  --button-warning-background-hover: rgb(224, 84, 62);
  --button-warning-border: rgb(224, 84, 62);
  --button-warning-text: rgb(224, 84, 62);
  --button-warning-text-hover: #fff;
  --button-light-background: rgb(255, 255, 255);
  --button-light-background-hover: #eee;
  --button-light-border: rgb(64, 64, 65);
  --button-light-text: #333;

  //Input
  --input-border-color: #ddd;
  --input-text-color: #333;
}
