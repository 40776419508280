.processing {
  flex-direction: column;
  h1 {
    display: block;
    clear: both;
    height: 25px;
    line-height: 25px;
    font-weight: 400;
    font-size: 22pt;
    padding: 10px 0 20px 0;
    color: var(--text-default);
    text-align: center;
  }
  span {
    display: block;
    clear: both;
    line-height: 20px;
    color: #777;
    margin: 15px 0 30px 0;
    text-align: center;
    &.left {
      text-align: left;
    }
    strong {
      font-weight: 600;
    }
  }
  p {
    display: block;
    clear: both;
    line-height: 20px;
    font-size: 14px;
    margin: 15px 0;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: var(--green);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .group {
    display: block;
    clear: both;
    padding: 10px 0 0 0;
    p {
      display: block;
      clear: both;
      line-height: 20px;
      line-height: 20px;
      margin: 0 0 5px 0;
      text-align: left;
    }
    .input {
      display: block;
      clear: both;
      box-sizing: border-box;
      line-height: 20px;
      border: 1px solid var(--input-border-color);
      border-radius: 4px;
      background: #fff;
      font-size: 14px;
      padding: 10px;
      width: 100%;
      color: var(--input-text-color);
      &:focus {
        outline: none;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        &:hover {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        &:focus {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        &:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }
    }
  }
  .action-button.offset {
    margin: 30px 0 0 0;
  }
}
