.loading {
  display: flex;
  position: relative;
  clear: both;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  .spinner {
    border-width: 6px;
    border-style: solid;
    border-color: rgb(101, 173, 90) rgb(101, 173, 90) rgb(101, 173, 90) transparent;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    animation: spin 1.5s infinite;
    position: relative;
    margin: 6em auto;
    &:before,
    &:after {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: rgb(101, 173, 90);
      position: absolute;
      left: 16px;
    }
    &:before {
      top: 15px;
    }
    &:after {
      bottom: 15px;
    }
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
  img {
    position: absolute;
    flex: 1;
    height: 100px;
    line-height: 100px;
    text-align: center;
    opacity: 0.9;
  }
}
