@import './styles/theme.scss';
@import './styles/loading.scss';
@import './styles/animations.scss';

html {
  display: flex;
  flex: 1;
  height: 100%;
  body {
    flex: 1;
    height: 100%;
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
    color: var(--text-default);
    .app {
      display: flex;
      flex: 1;
      height: 100%;
      position: relative;
      background: var(--background-main);
      flex-direction: column;
      .grid {
        position: absolute;
        flex: 1;
        right: 0;
        left: 0;
        bottom: 0;
        > img {
          display: flex;
          position: relative;
          float: right;
          right: 0;
          mix-blend-mode: multiply;
        }
        .provided {
          position: absolute;
          bottom: 40px;
          right: 40px;
          > span {
            line-height: 24px;
            > img {
              height: 24px;
              margin: 0 0 0 4px;
            }
          }
        }
      }
    }
  }
}
