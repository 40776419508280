.welcome {
  flex-direction: column;
  span {
    display: block;
    clear: both;
    line-height: 20px;
    color: #777;
    margin: 15px 0;
    text-align: center;
    &.left {
      text-align: left;
    }
    strong {
      font-weight: 600;
    }
  }
  .actions {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 20px;
    margin: 20px 0 0 0;
  }
}
