.login {
  flex-direction: column;
  h1 {
    display: block;
    clear: both;
    height: 25px;
    line-height: 25px;
    font-weight: 400;
    font-size: 22pt;
    padding: 10px 0 20px 0;
    color: var(--text-default);
    text-align: center;
  }
  span {
    display: block;
    clear: both;
    line-height: 20px;
    color: #777;
    margin: 15px 0;
    text-align: center;
    strong {
      font-weight: 600;
    }
  }
  span.error {
    color: red;
  }
  p {
    display: block;
    clear: both;
    line-height: 20px;
    font-size: 14px;
    margin: 15px 0;
  }
  p.forgot {
    text-align: right;
    margin: 10px 0;
  }
  a {
    text-decoration: none;
    color: var(--green);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .group {
    display: block;
    clear: both;
    padding: 20px 0 0 0;
    p {
      display: block;
      clear: both;
      line-height: 20px;
      line-height: 20px;
      margin: 0 0 5px 0;
    }
    .input {
      display: block;
      clear: both;
      box-sizing: border-box;
      line-height: 20px;
      border: 1px solid var(--input-border-color);
      border-radius: 4px;
      background: #fff;
      font-size: 14px;
      padding: 10px;
      width: 100%;
      color: var(--input-text-color);
      &:focus {
        outline: none;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        &:hover {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        &:focus {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        &:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }
    }
  }
  .action-button.offset {
    margin: 30px 0 0 0;
  }
}
.alert {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  gap: 10px;
  width: 440px;
  padding: 20px;
  border-radius: 10px;
  margin: 0 0 30px 0;
  background: rgb(240, 246, 254);
  color: rgb(53, 75, 209);
  align-items: center;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 0.8);
  -moz-box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 0.8);
  box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 0.8);
  > svg {
    flex: 0 1 18px;
    height: 18px;
  }
  span {
    flex: 1;
    line-height: 20px;
  }
  a {
    text-decoration: none;
    font-weight: 500;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
    svg {
      padding: 0 0 0 5px;
    }
  }
}
