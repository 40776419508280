.box {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 1;
  img {
    flex: 0 140px;
    height: 140px;
    text-align: center;
    z-index: 2;
    margin: 0 0 60px 0;
    // &.animate {
    //   animation: slideUp 0.5s ease-in-out both;
    // }
  }
  .inner {
    display: flex;
    flex: 0 1 auto;
    width: 400px;
    padding: 40px;
    background: #fff;
    border-radius: 10px;
    z-index: 1;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 0.8);
    -moz-box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 0.8);
    box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 0.8);
    // &.animate {
    //   animation: slideUp 0.5s ease-in-out both;
    // }
  }
}
