.setup {
  flex-direction: column;
  h1 {
    display: block;
    clear: both;
    height: 25px;
    line-height: 25px;
    font-weight: 400;
    font-size: 22pt;
    padding: 10px 0 20px 0;
    color: var(--text-default);
    text-align: center;
  }
  h2 {
    display: block;
    clear: both;
    height: 25px;
    line-height: 25px;
    font-size: 11pt;
    font-weight: 500;
  }
  span {
    display: block;
    clear: both;
    line-height: 20px;
    color: #777;
    margin: 5px 0;
    text-align: center;
    strong {
      font-weight: 600;
    }
  }
  span.error {
    color: red;
    padding: 20px 0 10px 0;
  }
  span.left {
    text-align: left;
  }
  span.no-pad {
    margin-top: 0;
  }
  .group {
    display: block;
    clear: both;
    padding: 20px 0 0 0;
    p {
      display: block;
      clear: both;
      line-height: 20px;
      line-height: 20px;
      margin: 0 0 5px 0;
    }
    .input {
      display: block;
      clear: both;
      box-sizing: border-box;
      line-height: 20px;
      border: 1px solid var(--input-border-color);
      border-radius: 4px;
      background: #fff;
      font-size: 15px;
      padding: 10px;
      width: 100%;
      color: var(--input-text-color);
      &:focus {
        outline: none;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        &:hover {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        &:focus {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        &:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }
    }
    .spacer {
      display: flex;
      flex: 0 8px;
      height: 8px;
    }
  }
  .qr {
    display: flex;
    flex: 1;
    justify-content: center;
  }
  .actions {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 20px;
    margin: 20px 0 0;
    &.flat {
      display: block;
    }
  }
  .line {
    display: flex;
    flex: 0 1px;
    background: #ddd;
    margin: 20px 0 15px 0;
  }
  .links {
    display: flex;
    flex: 0;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    a {
      height: 45px;
    }
    img {
      height: 45px;
    }
  }
}
